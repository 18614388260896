export default {
  message: {
    notification: "Notifications",
    notificationManagement: "Config Notify",
    notificationList: "Notifications",
    title: "Title",
    type: "Type",
    all: "All",
    importantNotfication: "Important Notifications",
    otherNotification: "Other Notifications",
    search: "Search",
    create: "Create",
    creator: "Creator",
    releaseTime: "Release Time",
    operation: "Operation",
    edit: "Edit",
    details: "Details",
    delete: "Delete",
    content: "Content",
    receiver: "Receiver",
    userName: "User Name",
    name: "Name",
    enterprise: "Enterprise",
    plzChooseTheType: "Please select Notification Category",
    plzEnterTheTitle: "Please enter the title.",
    plzEnterTheContent: "Please enter the content.",
    plzChooseTheReceiver: "Please choose the receiver.",
    noData: "NO Data",
    deleteMessageTips: "Confirm delete? This Company will no longer receive notifications once deleted.",
    allUsers: "All",
    specifiedUser: "Specified User",
    titleTips: "No more than 100 words are recommended",
    contentTips: "The content shall comply with laws and regulations",
    //230308 新增 可编辑 文言
    editType: "Notification Text Modify",
    editable: "Modifiable after announcement",
    notEditable: "Non-modifiable after announcement",
    duedit: "Allow modifications after posting",
    //240105 新增 发送邮件文言
    sendEmail: "Notify by email",
    delete: "Delete",
    recipientList: "Recipient List"
  }
};
