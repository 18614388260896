export default {
  message: {
    notification: "Notification",
    notificationManagement: "Мэдэгдлийн удирдлага",
    notificationList: "Мэдэгдэл",
    title: "Гарчиг",
    type: "Төрөл",
    all: "Бүгд",
    importantNotfication: "Чухал мэдэгдэл",
    otherNotification: "Мэдээлэл",
    search: "Хайх",
    create: "Үүсгэх",
    creator: "Үүсгэгч",
    releaseTime: "Гарах хугацаа",
    operation: "дэлгэрэнгүй",
    edit: "Засварлах",
    details: "Дэлгэрэнгүй",
    delete: "Устгах",
    content: "Агуулга",
    receiver: "Хүлээн авагч",
    userName: "Хэрэглэгчийн нэр",
    name: "Нэр",
    enterprise: "Аж ахуйн нэгж",
    plzChooseTheType: "Мэдэгдлийн төрлийг сонгоно уу",
    plzEnterTheTitle: "Please enter the title.",
    plzEnterTheContent: "Please enter the content.",
    plzChooseTheReceiver: "Please choose the receiver.",
    noData: "No Data",
    deleteMessageTips: "Устгахыг зөвшөөрч байна уу? Устгасны дараа энэ компани мэдэгдэл хүлээн авахгүй.",
    allUsers: "Бүгд",
    specifiedUser: "Тодорхой Хэрэглэгч",
    titleTips: "100-аас илүү үг оруулахгүй",
    contentTips: "Агуулга нь хууль тогтоомжинд нийцсэн байх ёстой",
    //230308 新增 可编辑 文言
    editType: "Мэдэгдлийн текстийг өөрчлөх",
    editable: "Нийтэлсний дараа өөрчлөх боломжтой",
    notEditable: "Нийтэлсний дараа өөрчлөх боломжгүй",
    duedit: "Нийтэлсний дараа дахин засварлах функц",
    //240105 新增 发送邮件文言
    sendEmail: "Имэйл мэдэгдэл",
    delete: "Устгах",
    recipientList: "Хүлээн авагчийн жагсаалт"
  }
};
