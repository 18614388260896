<template>
  <div class="app-wrapper">
    <sidebar class="sidebar-container" v-if="!childMode" />
    <el-container class="main-container" :class="{ 'sider-is-collapse': isCollapse }"
      :style="childMode ? 'margin-left:0' : ''">
      <main-header v-if="!childMode" />

      <div class="el-main" style="padding-top: 0 !important;padding-bottom: 0 !important;">
        <el-carousel class="messageGroup" direction="vertical" indicator-position="none" height="74px"
          v-if="importantMessageData.length > 0 && showMessage">
          <el-carousel-item v-for="item in importantMessageData" :key="item.messageId">
            <!-- <notification :message="item" :messageType="messageType" @click.native="goNoticeDetails(item)"></notification> -->
            <div class="messageItem">
              <el-row :gutter="10">
                <el-col :span="20" class="left" @click.native="goNoticeDetails(item)">
                  <div class="title">{{ item.messageTitle }}</div>
                  <div class="time">{{ item.createDate }}</div>
                </el-col>
                <el-col :span="4" class="right">
                  <span class="newTag">NEW</span>
                  <span class="closeBtn" @click="showMessage = false"></span>
                </el-col>
              </el-row>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <main-content v-if="!$store.state.app.userInfo.forceBindMfa"
        :style="showMessage ? 'height:calc(100vh - 160px)' : ''" />
    </el-container>
    <div v-if="!childMode" :class="!isCollapse ? 'collapse expand' : 'collapse close'" @click="setCollapse"></div>
  </div>
</template>
<script>
import Sidebar from "./components/Sidebar";
import MainHeader from "./components/Header";
import MainContent from "./components/Main";
import { messageList, getImportantMessage, getNormalMessage } from "api/message";

export default {
  name: "Layout",
  data() {
    return {
      showMessage: true,
      importantMessageData: [],
      messageType: "important",
      childMode: false
    };
  },
  components: {
    Sidebar,
    MainHeader,
    MainContent
  },
  watch: {
    '$route': {
      handler(to, from) {
        if (to.path === '/message') {
          this.showMessage = false;
        } else {
          this.showMessage = true;
        }
      },
      immediate: true
    }
  },
  created() {
    console.log('layout created', this.$route.query);

    if ('childmode' in this.$route.query) {
      this.childMode = true;
    }

    if (this.$settings.chatBot) {
      if (!this.childMode) {
        const script = document.createElement("script");
        script.id = "chatbot";
        script.async = true;
        script.src = this.$settings.chatBotApi;
        document.head.appendChild(script);
      } else {
        const script = document.getElementById("start_chat");
        if (script) {
          script.remove();
        }
      }
    }
  },
  mounted() {
    this.getImportantMessages();
  },
  methods: {
    goNoticeDetails(message) {
      console.log("message", message);
      // let index = message.messageType === 1 ? "important" : "other";
      this.$router.push({
        path: "/message",
        query: { activeTypeId: message.messageType, currentMessageId: message.messageId }
      });
    },
    getImportantMessages() {
      getImportantMessage({ PageIndex: 1, PageSize: 5, IgnoreExpiration: true }).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.importantMessageData = response.result;
        }
      });
    },
    setCollapse() {
      this.$store.commit("app/SET_SIDER_COLLAPSE", !this.isCollapse);
    }
  },
  computed: {
    isCollapse() {
      return this.$store.state.app.siderCollapse;
    }
  }
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  height: 100%;

  .main-container {
    flex-direction: column;
    margin-left: 260px;
    transition: 0.3s all ease;

    &.sider-is-collapse {
      margin-left: 112px;
    }
  }
}
</style>
